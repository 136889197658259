$background: #f5f5f5;

$primary: #bdbdbd;
$secondary: #ff3600;

$gamiPrimary: #bdbdbd;
$gamiSecondary: #fbab36;

:export {
  background: $background;

  primary: $primary;
  secondary: $secondary;

  gamiPrimary: $gamiPrimary;
  gamiSecondary: $gamiSecondary;
}
